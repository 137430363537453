<template>
  <esmp-modal
    v-model="show"
    title="Перестать отслеживать заявку?"
    :width="500"
    ok-text="Перестать"
    cancel-text="Следить"
    class-name="modal-ticket-stop-watching"
    @on-ok="stopWatch"
  >
    <p>
      Вы собираетесь удалить себя из наблюдателей. Вы больше не сможете
      просматривать информацию по этой заявке.
    </p>
  </esmp-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ModalTicketStopWatching',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceSystem: {
      type: String,
      default: '',
    },
    ticketId: {
      type: [String, Number],
      default: undefined,
    },
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    stopWatch() {
      this.setLoading({ key: 'app', value: true });
      this.$API.ticket.deleteWatcherFromTicket(this.ticketId, {
        source: this.sourceSystem,
      }).then(() => {
        this.$EsmpNotify.$show('Вы перестали наблюдать за этой заявкой', 'success');
        this.$router.push({
          name: 'Tickets',
          params: { category: 'watched' },
        });
      }).finally(() => this.setLoading({ key: 'app', value: false }));
    },
  },
};
</script>

<style lang="scss" scoped>
.esmp-modal-body {
  font-size: 16px;
}
</style>
